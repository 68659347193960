var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-card-title',[_c('v-combobox',{staticClass:"mr-7",attrs:{"label":"Enter username or profile link","hide-no-data":"","hide-details":"auto","append-icon":"","solo":"","chips":"","multiple":""},model:{value:(_vm.newUsers),callback:function ($$v) {_vm.newUsers=$$v},expression:"newUsers"}}),_c('v-btn',{attrs:{"color":"primary","large":"","loading":_vm.loading,"disabled":!_vm.newUsers.length},on:{"click":_vm.addUser}},[_vm._v(" Add user ")])],1),_c('v-card-text',[(_vm.selectedUsers.length>0)?_c('v-btn',{staticClass:"mb-5",attrs:{"icon":""},on:{"click":_vm.massDeleteUser}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDeleteForever))])],1):_vm._e(),_c('v-data-table',{attrs:{"hide-default-footer":"","items":_vm.users,"headers":_vm.accountHeaders,"show-select":"","server-items-length":_vm.total,"options":_vm.tableOptions,"footer-props":{
        'items-per-page-options': _vm.pages,
        'show-first-last-page': true,
        'show-current-page': true,
      }},on:{"update:options":function($event){_vm.tableOptions=$event}},scopedSlots:_vm._u([{key:"item.username",fn:function(ref){
      var value = ref.value;
return [_vm._v(" "+_vm._s(value)+" ")]}},{key:"item.last_sync",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.syncTime(item.last_sync))+" ")]}},{key:"item.status",fn:function(ref){
      var item = ref.item;
return [_c('TrueFalse',{attrs:{"value":item.is_active}})]}},{key:"item.detail",fn:function(ref){
      var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"to":{
                name: 'twitterSubscriptions',
                query: {username: item.username}
              },"plain":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiRadar))])],1)]}}],null,true)},[_c('span',[_vm._v("List subscriptions & unsubscriptions")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"to":{
                name: 'twitterCurrentSubscription',
                query: {user_id: item.id,name:item.username}
              },"plain":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPlaylistCheck))])],1)]}}],null,true)},[_c('span',[_vm._v("Current subscriptions list")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"plain":"","icon":"","href":("https://twitter.com/" + (item.username))}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiTwitter))])],1)]}}],null,true)},[_c('span',[_vm._v("Twitter profile")])])]}},{key:"item.action",fn:function(ref){
              var item = ref.item;
return [_c('TableActions',{attrs:{"loading":item.loading},on:{"edit":function($event){return _vm.openUserEditModal(item)},"delete":function($event){return _vm.deleteUser(item.id)}}})]}},{key:"footer",fn:function(ref){
              var props = ref.props;
              var on = ref.on;
return [_c('TableFooter',_vm._g(_vm._b({},'TableFooter',props,false),on))]}}]),model:{value:(_vm.selectedUsers),callback:function ($$v) {_vm.selectedUsers=$$v},expression:"selectedUsers"}})],1),_c('EditUser',{ref:"editUserModal",on:{"update":_vm.tryUpdateUser}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }