<template>
  <v-card
    :loading="loading"
  >
    <v-card-title>
      <v-combobox
        v-model="newUsers"
        label="Enter username or profile link"
        class="mr-7"
        hide-no-data
        hide-details="auto"
        append-icon=""
        solo
        chips
        multiple
      ></v-combobox>
      <v-btn
        color="primary"
        large
        :loading="loading"
        :disabled="!newUsers.length"
        @click="addUser"
      >
        Add user
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-btn
        v-if="selectedUsers.length>0"
        icon
        class="mb-5"
        @click="massDeleteUser"
      >
        <v-icon>{{ icons.mdiDeleteForever }}</v-icon>
      </v-btn>
      <v-data-table
        v-model="selectedUsers"
        hide-default-footer
        :items="users"
        :headers="accountHeaders"
        show-select
        :server-items-length="total"
        :options.sync="tableOptions"
        :footer-props="{
          'items-per-page-options': pages,
          'show-first-last-page': true,
          'show-current-page': true,
        }"
      >
        <template v-slot:item.username="{value}">
          <!-- <v-avatar>
            <v-img
              :alt="`${value} avatar`"
              :src="`https://api.twitter.com/2/users/by/username/${value}?user.fields=profile_image_url`"
            ></v-img>
          </v-avatar> -->

          {{ value }}
        </template>
        <template v-slot:item.last_sync="{item}">
          {{ syncTime(item.last_sync) }}
        </template>
        <template v-slot:item.status="{item}">
          <TrueFalse
            :value="item.is_active"
          ></TrueFalse>
        </template>
        <template v-slot:item.detail="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                :to="{
                  name: 'twitterSubscriptions',
                  query: {username: item.username}
                }"
                plain
                icon
                v-on="on"
              >
                <v-icon>{{ icons.mdiRadar }}</v-icon>
              </v-btn>
            </template>
            <span>List subscriptions & unsubscriptions</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                :to="{
                  name: 'twitterCurrentSubscription',
                  query: {user_id: item.id,name:item.username}
                }"
                plain
                icon
                v-on="on"
              >
                <v-icon>{{ icons.mdiPlaylistCheck }}</v-icon>
              </v-btn>
            </template>
            <span>Current subscriptions list</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                plain
                icon
                :href="`https://twitter.com/${item.username}`"
                v-on="on"
              >
                <v-icon>{{ icons.mdiTwitter }}</v-icon>
              </v-btn>
            </template>
            <span>Twitter profile</span>
          </v-tooltip>
        </template>
        <template v-slot:item.action="{ item }">
          <TableActions
            :loading="item.loading"
            @edit="openUserEditModal(item)"
            @delete="deleteUser(item.id)"
          />
        </template>
        <template v-slot:footer="{ props, on }">
          <TableFooter
            v-bind="props"
            v-on="on"
          >
          </TableFooter>
        </template>
      </v-data-table>
    </v-card-text>
    <EditUser
      ref="editUserModal"
      @update="tryUpdateUser"
    >
    </EditUser>
  </v-card>
</template>

<script>
import pick from 'lodash/pick'
import {
  mdiDeleteForever, mdiPlaylistCheck, mdiRadar, mdiTwitter, mdiArrowUp, mdiArrowDown,
} from '@mdi/js'

import TwitterApi from '@core/api/twitter'

import TrueFalse from '@/components/TrueFalse.vue'
import TableActions from '@/components/TableActions.vue'
import EditUser from '@/components/twitter/EditUser.vue'
import TableFooter from '@/components/TableFooter.vue'

export default {
  components: {
    TrueFalse,
    TableActions,
    EditUser,
    TableFooter,
  },
  data() {
    return {
      users: [],
      loading: false,
      newUsers: [],
      interval: '',
      accountHeaders: [
        {
          text: '',
          value: 'data-table-select',
        },
        {
          text: 'Username',
          value: 'username',
          sortable: true,
          filterable: true,
        },
        {
          text: 'Active',
          value: 'status',
          align: 'center',
          class: 'pr-0',
          cellClass: 'pr-0',
          sortable: false,
          filterable: true,
        },
        {
          value: 'last_sync',
          class: 'pl-1',
          cellClass: 'pl-1',
        },
        {
          value: 'detail',
          align: 'end',
        },
        {
          align: 'end',
          cellClass: 'w-38',
          value: 'action',
          sortable: false,
        },
      ],
      icons: {
        mdiDeleteForever,
        mdiPlaylistCheck,
        mdiRadar,
        mdiTwitter,
        mdiArrowUp,
        mdiArrowDown,
      },
      selectedUsers: [],
      isEditing: false,
      total: 0,
      pages: [50, 100, 200],
      tableOptions: {
        page: +this.$route.query.page ? +this.$route.query.page : 1,
        itemsPerPage: +this.$route.query.itemsPerPage ? +this.$route.query.itemsPerPage : localStorage.getItem('itemsPerPage') || 50,
      },
    }
  },
  computed: {
    usersId() {
      return this.selectedUsers.map(el => el.id)
    },
  },
  watch: {
    interval(value) {
      if (value > 0) {
        this.getUsers(value)
        this.$router.replace({ query: { interval: value } }).catch(() => {})
      } else {
        this.$router.replace({ query: null }).catch(() => {})
        this.getUsers()
      }
    },
    tableOptions: {
      handler(value) {
        const query = pick(value, ['page'])
        query.limit = value.itemsPerPage

        this.getUsers(query)
        this.$router.replace({ query }).catch(() => {})
      },
      deep: true,
    },
  },
  mounted() {
    this.getUsers()
    if (this.$route.query.interval) {
      this.interval = this.$route.query.interval
    }
  },
  methods: {
    async getUsers(query = this.$route.query) {
      this.loading = true
      const { data } = await TwitterApi.TrackableUsers.getUsers(query)
      this.loading = false
      this.users = data.items
      this.total = data.total
    },
    async addUser() {
      try {
        this.loading = true
        await TwitterApi.TrackableUsers.addUser({ users: this.newUsers })
        this.newUsers = []
        this.getUsers()
        this.loading = false
        this.$message.success('User added successfully')
      } catch (err) {
        this.loading = false
        this.$message.error(err)
      }
    },
    async deleteUser(id) {
      try {
        await TwitterApi.TrackableUsers.deleteUsers({ users: [id] })
        this.getUsers()
        this.$message.success('User deleted successfully')
      } catch (err) {
        this.$message.error(err)
      }
    },
    async massDeleteUser() {
      try {
        await TwitterApi.TrackableUsers.deleteUsers({ users: this.usersId })
        this.selectedUsers = []
        this.getUsers()
        this.$message.success('Users deleted successfully')
      } catch (err) {
        this.$message.error(err)
      }
    },
    setEditing() {
      this.isEditing = true
    },
    openUserEditModal(user) {
      this.$refs.editUserModal.open(pick(user, ['username', 'is_active']))
    },
    async tryUpdateUser(value) {
      try {
        await TwitterApi.TrackableUsers.updateUser(pick(value, ['username', 'is_active']))
        this.getUsers()
        this.$message.success('Record updated successfully')
      } catch (err) {
        this.$message.error(err)
      }
    },
    syncTime(time) {
      return time && time.replace('T', ' ').slice(0, time.length - 7)
    },
  },
}
</script>

<style lang="scss">
.w-38 {
  width: 38px;
}
</style>
