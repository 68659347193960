<template>
  <v-dialog
    v-model="isOpen"
    width="90vw"
    persistent
    @click:outside="tryClose"
  >
    <v-card>
      <v-card-title>
        Edit User
        <v-spacer />
        <v-btn
          icon
          @click="tryClose"
        >
          <v-icon>{{ icons.mdiClose }}</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col>
              <v-col>
                <label for="name">Username</label>
              </v-col>

              <v-col>
                <v-text-field
                  id="name"
                  v-model="formData.username"
                  outlined
                  dense
                  hide-details="auto"
                  placeholder="Enter username"
                  :rules="[required]"
                ></v-text-field>
              </v-col>

              <v-col>
                <v-switch
                  id="active"
                  v-model="formData.is_active"
                  label="Active"
                  dense
                  hide-details="auto"
                ></v-switch>
              </v-col>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :disabled="!isChanged"
          @click="submit"
        >
          Update
        </v-btn>
        <v-btn @click="tryClose">
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>

    <!--
      CONFIRM CANCEL UPDATE
    -->
    <ConfirmDialog
      ref="updateConfirm"
      :width="400"
      :z-index="99"
    >
      <template v-slot:default="{agree, cancel}">
        <v-card>
          <v-card-title>Confirm update cancel</v-card-title>
          <v-card-text>
            You have unsaved changes, you wanna leave
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              outlined
              @click="agree"
            >
              Agree
            </v-btn>
            <v-btn
              color="primary"
              @click="cancel"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </ConfirmDialog>
  </v-dialog>
</template>

<script>
import { mdiClose } from '@mdi/js'
import isEqual from 'lodash/isEqual'

import {
  required,
} from '@core/utils/validation'
import ConfirmDialog from '../ConfirmDialog.vue'

const INIT_DATA = {
  username: '',
  is_active: false,
}

export default {
  components: { ConfirmDialog },
  data() {
    return {
      icons: {
        mdiClose,
      },
      isOpen: false,
      initData: INIT_DATA,
      formData: INIT_DATA,
    }
  },
  computed: {
    isChanged() {
      return !isEqual(this.initData, this.formData)
    },
  },
  methods: {
    required,
    open(user) {
      this.isOpen = true
      this.formData = { ...user }
      this.initData = { ...user }
    },
    async submit() {
      if (this.$refs.form.validate()) {
        this.$emit('update', this.formData)
        this.reset()
      }
    },
    reset() {
      this.isOpen = false

      // this.formData = INIT_DATA
      // this.initData = INIT_DATA
    },
    async tryClose() {
      if (this.isChanged && await this.$refs.updateConfirm.open()) {
        this.reset()
      } else this.reset()
    },
  },
}
</script>

<style>

</style>
